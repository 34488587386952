<script>
import { mapActions, mapGetters } from 'vuex';
import InputEntry from '@/modules/daiwa-house-modular-europe/ProjectIntake/components/InputEntry.vue';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';
import { updateRecordV2 } from '@/services/api/v2/records.v2.api';

const namespace = 'dhmeProjectIntakeStore';

export default {
  name: 'DhmeProjectIntakeClient',
  components: {
    InputEntry,
  },
  data: () => ({
    client: { name: '', role: '', type: '', company: '' },
    rules: {
      required: (value) => !!value || 'Required.',
      maxLength: (value) =>
        (value && value.length < 250) || 'Max 250 characters',
    },
    addMenu: false,
    clientData: null,
    clientDataTableId: null,
  }),
  computed: {
    ...mapGetters({
      clients: 'dhmeProjectIntakeStore/clients',
      isLoading: 'dhmeProjectIntakeStore/isLoading',
      masterProject: 'project',
      intakeProject: 'dhmeProjectIntakeStore/selectedProject',
    }),
  },
  async mounted() {
    const { projectId } = this.$route.params;
    await this.loadClients(projectId);
    await this.fetchClientData();
  },
  methods: {
    ...mapActions({
      loadClients: 'dhmeProjectIntakeStore/loadClients',
      addClient: 'dhmeProjectIntakeStore/addClient',
      removeClient: 'dhmeProjectIntakeStore/removeClient',
    }),
    resetForm() {
      this.client = { name: '', role: '', type: '', company: '' };
      this.$refs.form.reset();
      this.addMenu = false;
    },
    addClientAction() {
      if (this.$refs.form.validate()) {
        this.addClient(this.client);
        this.resetForm();
      }
    },
    async fetchClientData() {
      const { clientdata } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_PROJECT_INTAKE_DATA',
            project: this.masterProject.id,
            as: 'clientdata',
            columns: [
              {
                name: 'project_id',
                conditions: [
                  {
                    operator: '=',
                    value: this.intakeProject.id,
                  },
                ],
              },
              ...[
                'company',
                'offer_type',
                'offered_module_state',
                'status',
                'calculation_amount',
                'construction_agreement',
                'tender',
                'agreed_calculation_amount',
                'minimal_margin',
                'prognosed_handover_date',
                'contracted_handover_date',
                'permit_applicant',
                'build_permit_duration',
              ].map((key) => ({ name: key })),
            ],
          },
        ],
      });

      this.clientData = clientdata.records[0];
      this.clientDataTableId = clientdata.id;
      this.isLoading = false;
    },
    async updateClientData(key, value) {
      this.isLoading = true;

      try {
        await updateRecordV2(this.clientDataTableId, this.clientData.id, {
          [key]: value,
        });
        this.clientData[key] = value;
      } catch (e) {
        this.$store.commit('showNotification', {
          content: e.message,
          color: 'error',
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<script setup>
import AntInput from '@/components/AntInput.vue';

const { log } = console;
</script>

<template>
  <div class="full-width overflow-y-auto d-flex">
    <v-card
      :loading="isLoading"
      class="d-flex flex-column flex-grow-1 mr-5 overflow-y-auto"
    >
      <v-card-title>Opdrachtgever informatie</v-card-title>
      <v-card-text class="flex-grow-1 overflow-y-auto">
        <v-form v-if="clientData">
          <v-row>
            <v-col class="flex-grow-1">
              <ant-input label="Bedrijf" top-margin="mt-0">
                <template #input-field>
                  <v-text-field
                    v-model="clientData.company"
                    filled
                    placeholder="Bedrijfsnaam"
                    @change="(value) => updateClientData('company', value)"
                  />
                </template>
              </ant-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="flex-grow-1">
              <ant-input label="Offerte Type" top-margin="mt-0">
                <template #input-field>
                  <v-select
                    v-model="clientData.offer_type"
                    filled
                    :items="[
                      'Huren',
                      'Kopen',
                      'Renovatie',
                      'Verplaatsing',
                      'Huren Kopen',
                      'Huren en Kopen',
                    ]"
                    placeholder="Type"
                    @change="(value) => updateClientData('offer_type', value)"
                  />
                </template>
              </ant-input>
            </v-col>
            <v-col>
              <ant-input label="Offerte module status" top-margin="mt-0">
                <template #input-field>
                  <v-select
                    v-model="clientData.offered_module_state"
                    filled
                    :items="['Nieuw', 'Gebruikt', 'Nieuw/Gebruikt']"
                    placeholder="Module status"
                    @change="
                      (value) => updateClientData('offered_module_state', value)
                    "
                  />
                </template>
              </ant-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="flex-grow-1">
              <ant-input label="Begroting" top-margin="mt-0">
                <template #input-field>
                  <v-text-field
                    v-model="clientData.calculation_amount"
                    filled
                    type="number"
                    placeholder="Hoeveelheid in euro's"
                    prepend-inner-icon="mdi-currency-eur"
                    @change="
                      (value) => updateClientData('calculation_amount', value)
                    "
                  />
                </template>
              </ant-input>
            </v-col>
            <v-col>
              <ant-input label="Geaccepteerde begroting" top-margin="mt-0">
                <template #input-field>
                  <v-text-field
                    v-model="clientData.agreed_calculation_amount"
                    filled
                    placeholder="Hoeveelheid in euro's"
                    prepend-inner-icon="mdi-currency-eur"
                    @change="
                      (value) =>
                        updateClientData('agreed_calculation_amount', value)
                    "
                  />
                </template>
              </ant-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="flex-grow-1">
              <ant-input label="Minimale Marge" top-margin="mt-0">
                <template #input-field>
                  <v-text-field
                    v-model="clientData.minimal_margin"
                    filled
                    placeholder="Marge"
                    prepend-inner-icon="mdi-percent"
                    @change="
                      (value) => updateClientData('minimal_margin', value)
                    "
                  />
                </template>
              </ant-input>
            </v-col>
            <v-col>
              <ant-input label="Tender" top-margin="mt-0">
                <template #input-field>
                  <v-switch
                    v-model="clientData.tender"
                    filled
                    @change="(value) => updateClientData('tender', value)"
                  />
                </template>
              </ant-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="flex-grow-1">
              <ant-input label="Bouwovereenkomst" top-margin="mt-0">
                <template #input-field>
                  <v-select
                    v-model="clientData.construction_agreement"
                    filled
                    :items="['Ja', 'Nee']"
                    placeholder="Bouwovereenkomst Ja of Nee"
                    @change="
                      (value) =>
                        updateClientData('construction_agreement', value)
                    "
                  />
                </template>
              </ant-input>
            </v-col>
            <v-col>
              <ant-input label="Engineering" top-margin="mt-0">
                <template #input-field>
                  <v-text-field
                    v-model="clientData.engineering"
                    filled
                    :items="['Bouwteam inbegrepen', 'Geen bouwteam']"
                    placeholder="Engineering"
                    @change="(value) => updateClientData('engineering', value)"
                  />
                </template>
              </ant-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="flex-grow-1">
              <ant-input label="Vergunningaanvrager" top-margin="mt-0">
                <template #input-field>
                  <v-select
                    v-model="clientData.permit_applicant"
                    filled
                    :items="['Door Ons', 'Door Klant']"
                    placeholder="Aanvrager"
                    @change="
                      (value) => updateClientData('permit_applicant', value)
                    "
                  />
                </template>
              </ant-input>
            </v-col>
            <v-col>
              <ant-input label="Vergunningsduur" top-margin="mt-0">
                <template #input-field>
                  <v-select
                    v-model="clientData.build_permit_duration"
                    filled
                    :items="[
                      'Permanent > 10 jaar (NL)',
                      'Tijdelijk t/m 10 jaar (NL)',
                      'Tijdlijk t/m 15 jaar (NL)',
                      'Permanent > 2 jaar (BE)',
                      'Tijdlijk < 2 jaar (BE)',
                    ]"
                    placeholder="Duur"
                    @change="
                      (value) =>
                        updateClientData('build_permit_duration', value)
                    "
                  />
                </template>
              </ant-input>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card>
      <v-data-table
        :items="clients"
        :headers="[
          { text: 'Naam', value: 'name' },
          { text: 'rol', value: 'role' },
          { text: 'Bedrijf', value: 'company' },
          { text: '', value: 'actions' },
        ]"
        class="flex-grow-1"
      >
        <template #item.actions="{ item }">
          <v-icon @click="removeClient(item)">mdi-delete</v-icon>
        </template>
        <template #top>
          <v-toolbar dense elevation="0">
            <v-toolbar-title>Stakeholders</v-toolbar-title>
            <v-spacer />

            <v-menu v-model="addMenu" :close-on-content-click="false">
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  elevation="0"
                  color="primary"
                  small
                  v-on="on"
                  ><v-icon>mdi-plus</v-icon>Voeg toe</v-btn
                >
              </template>
              <v-card>
                <v-card-title class="justify-center text-uppercase headline">
                  Add client
                </v-card-title>
                <v-divider />
                <v-form ref="form" @submit.prevent>
                  <div class="px-10 pb-5">
                    <ant-input label="Name" :is-optional="false">
                      <template #input-field>
                        <v-text-field
                          v-model="client.name"
                          hide-details
                          dense
                          :rules="[rules.required, rules.maxLength]"
                          filled
                          single-line
                          :autofocus="true"
                          placeholder="Name"
                          :disabled="!clients"
                          @change="(name) => (client.name = name)"
                        />
                      </template>
                    </ant-input>

                    <ant-input label="Role" :is-optional="false">
                      <template #input-field>
                        <v-select
                          v-model="client.role"
                          :items="[
                            'Tekenbevoegd',
                            'Projectontvanger',
                            'Architect',
                            'Adviseur',
                            'Contactpersoon klant',
                          ]"
                          hide-details
                          :rules="[rules.required, rules.maxLength]"
                          dense
                          filled
                          single-line
                          placeholder="Role"
                          :disabled="!clients"
                          @change="(role) => (client.role = role)"
                        />
                      </template>
                    </ant-input>

                    <ant-input label="Company" :is-optional="false">
                      <template #input-field>
                        <v-text-field
                          v-model="client.company"
                          hide-details
                          :rules="[rules.required, rules.maxLength]"
                          dense
                          filled
                          single-line
                          placeholder="Company"
                          :disabled="!clients"
                          @change="(role) => (client.company = role)"
                          @keyup.enter="addClientAction"
                        />
                      </template>
                    </ant-input>
                  </div>
                  <v-card-actions class="ant-border-top ant-dialog-actions-bg">
                    <v-spacer />
                    <v-btn color="error" small text @click.stop="resetForm">
                      Cancel
                    </v-btn>
                    <v-btn
                      color="primary"
                      small
                      elevation="0"
                      @click.stop="addClientAction"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-menu>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
